
.logoContainer{
    width: 600px;
    display: flex;
    position: absolute;
    top:7%;
    left:10%;
    background-color: rgb(240, 255, 255,0.5);
    color: white;
    border-radius: 20px;
    /* text-align: center; */
    
}
.logoName{
    font-size:80px;
    color: white;
    
}

.infoContent{
    color: antiquewhite;
    
}
.infoContent>h1,h2,h3,h4,h5{
    padding: 1rem;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}


