
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: rgb(15, 15, 15);
  color: white;
}

li {
  list-style: none;
}

.container {
  padding-inline: 1rem;
  max-width: 1100px;
  margin-inline: auto;
}

.roadmap {
  padding-block: 2rem;
  height: 100%;
}

.roadmap h1 {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 2.2rem;
}

/* .roadmap__timeline {
  position: relative;
  top:7rem;
} */

.roadmap__line {
  position: absolute;
  /* left: -0.2rem; */
  height: 110%;
  width: 4px;
  background-color: rebeccapurple;
}

.roadmap-item {
  margin-bottom: 2rem;
  position: relative;
  transition: all 1s;
}



.roadmap-item__marker {
  position: absolute;
  left: -0.2rem;
  z-index: 1;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rebeccapurple;
}

.roadmap-item__content {
  background-color: rgb(183, 183, 183,0.6);
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  transition: all 0.5s;
  z-index: 10;
}

.roadmap-item__content:hover {
  background-color: rgb(183, 183, 183);
  cursor: pointer;
}

.roadmap-item__content>img{
    width: 200%;
    height: 100%;
    align-self: center;
    padding: 1rem;
  }

.roadmap-item__stage {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

.roadmap-item__detail {
  margin-bottom: 0.5rem;
}

.roadmap-item__separator{
    padding: 20px;
}

/* Media query */
@media (min-width: 768px) {
  .roadmap__line {
    left: 52%;
  }

  .roadmap-item__marker {
    left: 51.3%;
  }

  .roadmap-item__stage {
    font-size: 2rem;
  }

  .roadmap-item--odd {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row-reverse;
  }


  .roadmap-item--odd .roadmap-item__content {
    margin-right: -15rem;
    width: 60%;
  }

  .roadmap-item--even .roadmap-item__content {
    margin-left: -10rem;
    width: 60%;
  }

}