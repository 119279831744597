

#menuBar{
    display: inline-flex; 
    flex-direction: row;
    justify-content: space-evenly; 
    align-items: baseline;
    align-self: flex-end;
    height: auto;
    width: max-content;
    font-size: 30px;
    font-family:'Courier New', Courier;
    font-weight:500;
    color: #000;
    background-color: gray;
    border-radius: 20px 0px 0px 20px;
    transition: all 1s;
    position: fixed;
    right:0;
    top:2%;
    z-index:100;

}

#menuBar:hover{
    width:max-content
}
#menuBar>a{
    margin: 2.5rem;
}

.headerContainer{
    padding-top: 1%;
}

.pageTitle{
    align-self: center;
}

.pageContainer{
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.mainBody{
    position: relative;
}

.page{
    width: 100%;
    height: fit-content;
    background: linear-gradient(to bottom, rgb(90, 90, 90), transparent);
}

.bodyContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

footer{
    z-index: 10;
    background-color: transparent;
}

.ptt-logo {
    background-image:url("http://www.ipttc.org/logos/colour/PTT-110px.png");
    width:110px;
    height:110px
  }